.clear {
    clear: both;
}
dt {
    font-size: 1rem;
    text-align: right;
    padding: 0 26px 0 0;
    width: 150px;
    float: left;
    font-weight: normal !important;
}
dd {
    width: 600px;
    float: left;
    padding-left: 40px;
    border-left: 1px solid #432342;
    li {
        list-style: none;
    }
}


@media only screen and (max-width: 1530px) {
    dd {
        width: unset;
        max-width: 600px;
        border-left: unset;
        li {
            list-style: square;
        }
    }

    dt {
        width: unset;
        text-align: left;
        padding-bottom: 20px;
        font-size: 20px;
    }
}