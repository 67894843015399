.content-frame {
  max-width: 670px;
  margin: 0 auto;
}


@media only screen and (min-width: 1500px) {
  .content-frame {
    max-width: 960px;
  }
}