.sidenav {
  min-width: 280px;
  max-width: 400px;
  min-height: 100vh;
  background-color: #5C475A;
  opacity: 1;
  padding-top: 56px;

  .fixed {
    position: fixed;
    max-width: 400px;
  }

  .hline {
    width:100%;
    height:1px;
    background: #F0EFF4
  }

  .side-link {
    color: #F0EFF4;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      letter-spacing: 1px;
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .sidenav {
    position: absolute;
    left: -100vw;
    transition: left 0.3s;
    z-index: 1000;
    min-height: 100vh;
    &.open {
      left: 0;
    }

    .fixed {
      position: fixed;
      background-color: #5C475A;
      min-height: 100vh;
    }
  }
}